@import '@/assets/css/index.scss';

.showMore {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: $content-padding;
    color: var(--color-endeavour);
    cursor: pointer;
}

.arrowDown,
.arrowUp {
    margin-left: $content-padding;

    @include desktop {
        display: none;
    }
}

.arrowDown {
    rotate: 90deg;
}

.arrowUp {
    rotate: -90deg;
}

.showMoreButton {
    font-size: 14px;

    @include desktop {
        font-size: 12px;
    }
}
