@mixin flex-gap($gap: 10px, $vertical-gap: $gap) {
    display: flex;
    column-gap: $gap;
    row-gap: $vertical-gap;
}

@mixin flex-gap-vertical($gap: 10px) {
    display: flex;
    flex-direction: column;
    row-gap: $gap;
}

@mixin flex-gap-horizontal($gap: 10px) {
    display: flex;
    flex-direction: row;
    column-gap: $gap;
}
