$safe-area-bottom: env(safe-area-inset-bottom, 0);

$content-padding: 10px;
$content-padding--small: 5px;
$content-padding--medium: 15px;
$content-padding--large: 20px;
$content-padding--extra-large: 25px;

$font-size-default: 16px;
$font-size-extra-small: 12px;
$font-size-small: 14px;
$font-size-medium: 18px;
$font-size-large: 20px;
$overlay-header-height: 40px;

// desktop
$content-padding-desktop: 20px;
$content-padding-desktop--medium: 15px;
$content-padding-desktop--small: 10px;
$content-padding-desktop--extra-small: 5px;

$min-width-desktop: calc(1024px - 20px);
$max-width-desktop: calc(1300px - 20px);
$boxShadow: 0 1px 2px rgba(0, 0, 0, 0.2);
$boxShadow-large: 0 2px 3px rgba(0, 0, 0, 0.26);
$font-size-default-desktop: 14px;
$font-size-body-default-desktop: 15px;
$font-size-title-default-desktop: 16px;
$font-size-medium-desktop: 18px;
$font-size-small-desktop: 12px;
