@import '@/assets/css/index.scss';

.searchInput {
    width: 100%;
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-nobel);
    transition: all 0.3s ease;
    flex-grow: 1;

    @include desktop {
        border: 1px solid var(--color-nobel);
        padding: 0 $content-padding;
        border-radius: 3px;
    }
}

.filterSearchInputFocused {
    border-bottom-color: var(--color-endeavour);

    @include desktop {
        border-color: var(--color-endeavour);
    }
}

.wrapper {
    position: relative;
    width: 100%;
    @include flex-gap-horizontal($content-padding);
}

.input {
    &::placeholder {
        color: var(--color-nobel);
    }
    overflow: hidden;
    max-width: 100%;
    box-sizing: border-box;
    padding-right: 40px;
}

.resetButton {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
