@mixin app {
    :global(.body--app) & {
        @content;
    }
}

@mixin app-ios {
    :global(.body--app.body--iOS) & {
        @content;
    }
}

@mixin ios {
    :global(.body--iOS) & {
        @content;
    }
}

@mixin android {
    :global(.body--android) & {
        @content;
    }
}

@mixin desktop {
    :global(.body--desktop) & {
        @content;
    }
}

@mixin safari {
    /* Safari 12-15 and 16+ 
   https://stackoverflow.com/questions/73868326/css-hack-that-also-supports-ios16
*/
    @supports (-webkit-hyphens: none) {
        @content;
    }
}
